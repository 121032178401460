import React from 'react';

const applicationidjson = (props) => {

  let data = ``
  let name = 'null'
  let ratingValue = 'null'
  let bestRating = 'null'
  let worstRating = 'null'
  let ratingCount = 'null'

  let locationGroup = []
  props.locationGroup.map(item => {
    locationGroup.push(
      item.node.path.alias
      .toString()
      .replace(/(\/)/g, '')
      .toLowerCase())
    })

  if(props.allPracticemaxRatings){
    if(props.allPracticemaxRatings.edges){

      let starsdata = props.allPracticemaxRatings.edges.filter((location) => {
        return locationGroup.includes(location.node.shortName.toLowerCase()) ? true : false;
      });

      starsdata.map((centerRating) => {
        name = centerRating.node.medicalOrganizationEntity.name
        if(centerRating.node.medicalOrganizationEntity.aggregateRating){
          ratingValue = centerRating.node.medicalOrganizationEntity.aggregateRating.ratingValue
          bestRating = centerRating.node.medicalOrganizationEntity.aggregateRating.bestRating
          worstRating = centerRating.node.medicalOrganizationEntity.aggregateRating.worstRating
          ratingCount = centerRating.node.medicalOrganizationEntity.aggregateRating.ratingCount
        }

        data += `
        <script type="application/ld+json">
        {
          "@context": "http://schema.org",
          "@type": "MedicalOrganization",
          "name": "${name}",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingCount": ${ratingCount},
              "bestRating": ${bestRating},
              "worstRating": ${worstRating},
              "ratingValue": ${ratingValue}
          }
        }
        </script>`
      })   
    }
  }

  return (
      <div dangerouslySetInnerHTML={{__html: data}} />
  )
}

export default applicationidjson;